"use client"

import { useSelectedLayoutSegment } from "next/navigation"
import { SpeedInsights } from "@vercel/speed-insights/next"
import { env } from "~/env"
import { clsx } from "clsx"
import { NuqsAdapter } from "nuqs/adapters/react"

import type { ExplicitAny, PropsWithClassName } from "~/@types/generics"
import { isDarel } from "~/lib/brand"
import { getLang } from "~/lib/i18n/utils/get-i18n"
import { isVercelProduction } from "~/lib/vercel/constants"
import { ErrorBoundary } from "~/components/abstracts/ErrorBoundary"
import isDev from "~/utils/is-dev"

import { neue, secondFont } from "~/styles/fonts"
import { themeClass } from "~/styles/theme.css"
import { customVariablesNames } from "~/styles/variables/customs"

import * as css from "./styles.css"

export function ClientRootLayout({ children }: PropsWithClassName) {
  const locale = useSelectedLayoutSegment()
  const theme = clsx(themeClass, secondFont.className, secondFont.variable, neue.className, neue.variable)

  return (
    <html lang={getLang(locale)}>
      <body
        className={theme}
        style={{
          [customVariablesNames.scrollBar as ExplicitAny]: "0px",
          [customVariablesNames.scrollBarGrid as ExplicitAny]: "0px",
          [customVariablesNames.stableVh as ExplicitAny]: "1vh",
          [customVariablesNames.vh as ExplicitAny]: "1vh",
          [customVariablesNames.vh100 as ExplicitAny]: "100vh",
        }}
      >
        {isVercelProduction && (
          <ErrorBoundary>
            <SpeedInsights />
          </ErrorBoundary>
        )}
        <NuqsAdapter>
          <div className={css.next} id="__next">
            {children}
          </div>
        </NuqsAdapter>
        {!isDev && (
          <noscript>
            <iframe
              src={`${isDarel ? "https://metrics.gerarddarel.com" : "https://metrics.pablo.fr"}/ns.html?id='${
                env.NEXT_PUBLIC_GTM_ID
              }'`}
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            />
          </noscript>
        )}
      </body>
    </html>
  )
}
